export function add_hyphen(unitid, language) {
  let val;
  const unitidNum = unitid.replaceAll('@','');
  switch (unitidNum.length) {
    case 15: //@@xxx-xxxx-xxxx
      var t1 = unitidNum.substr(0, 3);
      var t2 = unitidNum.substr(3, 4);
      var t3 = unitidNum.substr(7, 4);
      var t4 = unitidNum.substr(12, 2);
      t4 = deviceType(t4, language);
      val = t1 + "-" + t2 + "-" + t3 + "(" + t4 + ")";
      break;
    case 11:
      var t1 = unitidNum.substr(0, 3);
      var t2 = unitidNum.substr(3, 4);
      var t3 = unitidNum.substr(7, 4);
      val = t1 + "-" + t2 + "-" + t3;
      break;
    default:
      val = unitid;
      break;
  }
  return val;
}
export function trimType(unitid) {
  let val;
  switch (unitid.length) {
    case 17:
      val = unitid.substr(0, 13);
      break;
    default:
      val = unitid;
      break;
  }
  return val;
}
export function calcDigits(d) {
  return d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function distance(d) {
  return d.value.toFixed(2) + "(km)";
}
export function eventcodeStatus(e, s) {
  switch (e) {
    case "05":
      return parseInt(s) > 0 ? "start" : "pause";
    case "04":
      return "end";
    case "03":
      return "pause";
    default:
      return "pause";
  }
}

export function eventcodeKOR(e, s) {
  const kor = eventcodeStatus(e, s);
  switch (kor) {
    case "start":
      return "주행";
    case "pause":
      return "정차";
    case "end":
      return "종료";
    default:
      return "정차";
  }
}
export function eventcodeENG(e, s) {
  const eng = eventcodeStatus(e, s);
  switch (eng) {
    case "start":
      return "Driv.";
    case "pause":
      return "Pause";
    case "end":
      return "End";
    default:
      return "Pause";
  }
}
export function gpsStatusKOR(v) {
  if (v > 9) {
    return "원활";
  } else if (v > 7) {
    return "좋음";
  } else if (v > 5) {
    return "보통";
  } else if (v > 0) {
    return "낮음";
  } else {
    return "음영";
  }
}
export function gpsStatusENG(v) {
  if (v > 9) {
    return "Excellent";
  } else if (v > 7) {
    return "Good";
  } else if (v > 5) {
    return "Normal";
  } else if (v > 0) {
    return "Low";
  } else {
    return "Shading;";
  }
}
export function deviceType(v, l) {
  if (v.includes("유선")) {
    return v.replace("유선", l === "KOR" ? "유선" : "wire");
  } else if (v.includes("무선")) {
    return v.replace("무선", l === "KOR" ? "무선" : "wireless");
  } else {
    return v;
  }
}
export function btocYn(v, l) {
  switch (v) {
    case "Y":
      return l === "KOR" ? "개인" : "priv.";
    case "N":
      return l === "KOR" ? "기업" : "com";
  }
}
export function orgType(v, l) {
  switch (v) {
    case "C":
      return l === "KOR" ? "회사" : "com";
    case "B":
      return l === "KOR" ? "지점" : "branch";
    case "A":
      return l === "KOR" ? "대리점" : "agent";
  }
}
export function grade(v, l) {
  switch (v) {
    case "AD":
      return l === "KOR" ? "관리자" : "Admin";
    case "CS":
      return l === "KOR" ? "일반고객" : "Customer";
    case "DV":
      return l === "KOR" ? "기사" : "Driver";
    case "FDV":
      return l === "KOR" ? "고정기사" : "Fixed Driver";
  }
}
export function depart(v, l) {
  switch (v) {
    case "기업부설연구소":
      return l === "KOR" ? "기업부설연구소" : "RND Lab";
    case "연구개발팀":
      return l === "KOR" ? "연구개발팀" : "RND Team";
    case "영업팀":
      return l === "KOR" ? "영업팀" : "Sales";
    default:
      return v;
  }
}
export function ISODateToDateTime(date) {
  return date.replace("T", " ").substring(0, 19);
}
export function ISODateToDate(date) {
  return date.replace("T", " ").substring(0, 10);
}
export function checkHp(hp) {
  return /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}$/.test(hp);
}
export function checkValidInput(input) {
  const forbiddenCharacters = /['";`\\]|(\b(OR|AND|UNION|SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|CREATE|EXEC|EXECUTE|SHUTDOWN|MERGE|HAVING|TRUNCATE|REPLACE|GRANT|REVOKE|DESCRIBE)\b)/gi;
  const forbiddenHTMLTags = /<[^>]*>/g;
  const forbiddenPatterns = [
      /\bOR\b.*\b=\b/i,        // OR 조건
      /\bAND\b.*\b=\b/i,       // AND 조건
      /UNION\s+(ALL\s+)?SELECT/i, // UNION SELECT 패턴
      /\bSELECT\b.*\bFROM\b/i, // SELECT ... FROM 패턴
      /--/,                     // 주석 패턴
      /\bDROP\b.*\bTABLE\b/i,  // DROP TABLE 패턴
      /\bINSERT\b.*\bINTO\b/i, // INSERT INTO 패턴
      /\bDELETE\b.*\bFROM\b/i, // DELETE FROM 패턴
      /\bUPDATE\b.*\bSET\b/i,  // UPDATE SET 패턴
      /\bEXEC\b(\s+|UTE)/i     // EXECUTE 패턴
  ];

  if(input instanceof Object) {
      return checkValidObj(input);
  }

  // 금지된 특수 문자나 SQL 키워드가 있는지 검사합니다.
  if (forbiddenCharacters.test(input)) {
      // throw new Error("입력값에 SQL Injection 가능성이 있는 특수문자나 키워드가 포함되어 있습니다.");
      return false;
  }

  // html 태그를 포함하는지 검사합니다.
  if (forbiddenHTMLTags.test(input)) {
    return false;
  }

  // 금지된 패턴이 포함된 경우 에러를 반환합니다.
  for (const pattern of forbiddenPatterns) {
      if (pattern.test(input)) {
          // throw new Error("입력값에 SQL Injection 가능성이 있는 패턴이 포함되어 있습니다.");
          return false;
      }
  }

  // 입력이 유효한 경우 그대로 반환
  return true;
}
export function checkValidObj (obj) {
  try {
    //maxLengthConfig 인자를 생략했을 때, 빈 객체를 기본값으로 사용
    const entries = Object.entries(obj);
    let result = true;
  
    for (const [key,value] of entries) {
      if(!checkValidInput(value)) {
        result = false;
        break;
      }
    }
    return result;
  } catch (error) {
    return false;
  }
}