import React, { useEffect } from "react";
import axios from "../../../lib/axios.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useAuth } from "../../../context/LoginContext";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useState } from "react";
import { dataList } from "./SetMain.jsx";
import TelInput from "../../Atomic/atoms/Tel_Input";
import Email from "../../Atomic/atoms/Email";
import moment from "moment";
import Select from "../../Atomic/molecules/Select";
import { checkValidObj } from "../../../lib/common_func.js";

export default function Custom() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();

  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState({
    nm: "",
    id: "",
    pwdNew: "",
    pwdNew2: "",
    hp: "010-0000-0000",
    tel: "",
    email: "",
    postnum: "",
    addr: "",
    addr_dt: "",
    makedate: "",
    grade: "",
    act_yn: "",
    all_yn: "",
    menu_auth: "",
    idx: "",
    org_idx: "",
  });
  const [postRef, setPostRef] = useState(React.createRef());
  const [mainAddrRef, setMainAddrRef] = useState(React.createRef());
  const [subAddrRef, setSubAddrRef] = useState(React.createRef());
  const [pwdChange, setPwdChange] = useState(false);
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  // Function definition
  //==================================================================
  async function pfwdChangeFun() {
    const result = await Swal.fire({
      title: "현재 비밀번호를 입력해주세요.",
      showCancelButton: true,
      input: "password",
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    });
    
    if (result.isConfirmed) {
      if (result.value === "") {
        Swal.fire({
          title: "비밀번호를 입력해주세요.",
        });
        return;
      }

      const sendRequest = async () => {
        try {
          const res = await axios.post("/web/settings/user/edit", { ...rawData, pwd: result.value });

          if (res.status === 200) {
            Swal.fire({
              title: "비밀번호가 변경되었습니다.",
            }).then((result) => {
              navi("/Set");
            });
          } else {
            Swal.fire({
              title: "비밀번호 변경에 실패하였습니다.",
            });
          }
        } catch (error) {
          console.error("Error updating password:", error);
        }
      };

      await sendRequest();
    }
  }

  const hpHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "hp1":
        temp = rawData.hp.split("-");
        temp[0] = value;
        if (temp[0] === "000") {
          alert("0000은 입력할 수 없습니다.");
          temp[0] = "";
        }

        setRawData((prev) => ({ ...prev, hp: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      case "hp2":
        temp = rawData.hp.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }

        setRawData((prev) => ({ ...prev, hp: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      case "hp3":
        temp = rawData.hp.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }

        setRawData((prev) => ({ ...prev, hp: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      default:
        break;
    }
  };
  const TelHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "tel1":
        temp = rawData.tel.split("-");
        temp[0] = value;
        if (temp[0] === "000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      case "tel2":
        temp = rawData.tel.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      case "tel3":
        temp = rawData.tel.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") === '--' ? '' : temp.join("-") }));
        break;
      default:
        break;
    }
  };
  function submitFun() {
    Swal.fire({
      title: "수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        createNew();
      }
    });
  }
  function createNew() {
    if (rawData.nm === "") {
      Swal.fire({
        title: "사용자명 입력해주세요.",
      });
      return;
    }

    if (rawData.hp && rawData.hp.length < 13) {
      Swal.fire({
        title: "휴대폰 번호 입력해주세요.",
      });
      return;
    }

    if (rawData.pwdNew !== "" && rawData.pwdNew) {
      if (rawData.pwdNew.length < 4) {
        Swal.fire({
          title: "비밀번호는 4자리 이상 입력해주세요.",
        });
        return;
      }
      if (rawData.pwdNew2 === undefined) {
        Swal.fire({
          title: "비밀번호를 입력해주세요.",
        });
        return;
      }
      if (rawData.pwdNew !== rawData.pwdNew2) {
        Swal.fire({
          title: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }
    }

    if (!checkValidObj(rawData)) {
      Swal.fire({
        title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists'),
      });
      return;
    }

    if (user.org_idx == 1350) {
      Swal.fire({
        title: "데모체험에서는 제공되지 않는 기능입니다",
      });
      return;
    }

    if (pwdChange) {
      pfwdChangeFun();
      return;
    }
    
    axios.post("/web/settings/user/edit", rawData).then((res) => {
      
      if (res.status === 200) {
        Swal.fire({
          title: "수정되었습니다.",
        }).then((result) => {
          navi("/Set");
        });
      } else {
        Swal.fire({
          title: "수정에 실패하였습니다.",
        });
      }
    });
  }
  const handleComplete = (data) => {
    var fullAddr = ""; // 최종 주소 변수
    var extraAddr = ""; // 조합형 주소 변수
    if (data.userSelectedType === "R") {
      fullAddr = data.roadAddress;
    } else {
      fullAddr = data.jibunAddress;
    }
    if (data.userSelectedType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
    }
    postRef.current.value = data.zonecode;
    mainAddrRef.current.value = fullAddr;
    subAddrRef.current.focus();
    setRawData((prev) => ({
      ...prev,
      postnum: data.zonecode,
      addr: fullAddr,
    }));
  };
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  function inputHandler(e) {
    const { name, value } = e.target;
    setRawData((prev) => ({ ...prev, [name]: value }));
  }
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    if (user) {
      async function getData() {
        try {
          await axios
            .post("/web/settings/user/detail", {
              org_idx: user.org_idx,
              idx: user.idx,
            })
            .then((res) => setRawData(res.data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      getData();
    }
  }, [user]);
  useEffect(() => {
    if (rawData.pwdNew !== "" && rawData.pwdNew !== undefined) {
      setPwdChange(true);
    } else {
      setPwdChange(false);
    }
  }, [rawData]);
  // Data definition
  //==================================================================
  
  const buttonData = [
    {
      labelKR: "취소",
      labelEN: "Cancel",
      classN: "purple",
      clickFun: () => {
        navi("/Set");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "수정",
      labelEN: "Registration",
      classN: "purple",
      icon: true,
      clickFun: () => {
        submitFun();
      },
      position: "right",
    },
  ];
  //==================================================================

  //==================================================================

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set/Custom"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "개인 설정" : "Personal settings"}
                </div>
              </div>
              <div className="sub-page-body">
                <table className="common-view-table">
                  <colgroup>
                    <col style={{ width: "19rem" }} />
                    <col />
                    <col style={{ width: "19rem" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th className="required">{language === "KOR" ? "사용자명" : "Agent name"}</th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="nm"
                            id="nm"
                            placeholder="사용자명을 입력해주세요"
                            value={rawData.nm}
                            onChange={(e) => {
                              setRawData({ ...rawData, nm: e.target.value });
                            }}
                          />
                        </div>
                      </td>
                      <th className="required">
                        {language === "KOR"
                          ? "회사코드 / 사용자 ID"
                          : "Company code / user ID"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="id"
                            id="id"
                            placeholder="회사코드 / 사용자 ID를 입력해주세요"
                            value={rawData.id}
                            onChange={(e) => {
                              setRawData({ ...rawData, id: e.target.value });
                            }}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required">
                        {language === "KOR"
                          ? "비밀번호"
                          : "Password / Password"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="password"
                            name="pwdNew"
                            id="pwdNew"
                            placeholder="비밀번호를 입력해주세요"
                            value={rawData.pwdNew || ""}
                            onChange={(e) => {
                              setRawData({
                                ...rawData,
                                pwdNew: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                      <th className="required">
                        {language === "KOR"
                          ? "비밀번호 확인"
                          : "Password confirm"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="password"
                            name="pwdNew2"
                            id="pwdNew2"
                            placeholder="비밀번호를 입력해주세요"
                            value={rawData.pwdNew2 || ""}
                            onChange={(e) => {
                              setRawData({
                                ...rawData,
                                pwdNew2: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className="required">
                        {language === "KOR"
                          ? "휴대폰 번호"
                          : "Mobile phone number"}
                      </th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="hp1"
                            name="hp1"
                            value={
                              (rawData.hp === null || rawData.hp === undefined)
                                ? ""
                                : rawData.hp.split("-")[0]
                            }
                            changeFun={hpHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={3}
                          />
                          -
                          <TelInput
                            id="hp2"
                            name="hp2"
                            value={
                              rawData.hp === null || undefined
                                ? ""
                                : rawData.hp.split("-")[1]
                            }
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                          -
                          <TelInput
                            id="hp3"
                            name="hp3"
                            value={
                              rawData.hp === null || undefined
                                ? ""
                                : rawData.hp.split("-")[2]
                            }
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                      <th>
                        {language === "KOR" ? "직통번호" : "Telephone number"}
                      </th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="tel1"
                            name="tel1"
                            value={
                              rawData.tel === null || undefined
                                ? ""
                                : rawData.tel.split("-")[0]
                            }
                            changeFun={TelHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={3}
                          />
                          -
                          <TelInput
                            id="tel2"
                            name="tel2"
                            value={
                              rawData.tel === null || undefined
                                ? ""
                                : rawData.tel.split("-")[1]
                            }
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                          -
                          <TelInput
                            id="tel3"
                            name="tel3"
                            value={
                              rawData.tel === null || undefined
                                ? ""
                                : rawData.tel.split("-")[2]
                            }
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {language === "KOR" ? "이메일" : "E-mail address"}
                      </th>
                      <td>
                        <Email
                          name="email"
                          id="email"
                          value={
                            rawData.email === null || undefined
                              ? ""
                              : rawData.email
                          }
                          setRawData={setRawData}
                        />
                      </td>
                      <th>
                        {language === "KOR" ? "ID 생성일" : "Id creation date"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="makedate"
                            id="makedate"
                            onChange={(e) => {
                              setRawData({
                                ...rawData,
                                makedate: e.target.value,
                              });
                            }}
                            value={moment
                              .utc(rawData.makedate)
                              .format("YYYY-MM-DD")}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th rowSpan={3} className="required">
                        {language === "KOR"
                          ? "회사주소"
                          : "Address of company"}
                      </th>

                      <td colSpan={1} rowSpan={3}>
                        <div className="address">
                          <div className="row">
                            <div className="custom-input">
                              <input
                                id="postnum"
                                name="postnum"
                                type="text"
                                readOnly
                                ref={postRef}
                                value={rawData.postnum}
                              />
                            </div>
                            <button
                              onClick={handleClick}
                              type="button"
                              className="common-btn white sm"
                            >
                              <span>
                                {language === "KOR"
                                  ? "우편번호 검색"
                                  : "Addr search"}
                              </span>
                            </button>
                          </div>
                          <div className="row">
                            <div className="custom-input full">
                              <input
                                id="addr"
                                name="addr"
                                type="text"
                                ref={mainAddrRef}
                                value={rawData.addr}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="custom-input full">
                              <input
                                id="addr_dt"
                                name="addr_dt"
                                type="text"
                                ref={subAddrRef}
                                onChange={inputHandler}
                                value={rawData.addr_dt}
                                placeholder={
                                  language === "KOR"
                                    ? "상세주소를 입력해주세요."
                                    : "Please enter your detailed address."
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        {language === "KOR"
                          ? "Map 화면갱신주기"
                          : "Map refresh cycle"}
                      </th>
                      <td colSpan={1}>
                        <Select
                          id="refresh_time"
                          short={"N"}
                          name="refresh_time"
                          options={[
                            {
                              name: language === "KOR" ? "미설정" : "No",
                              value: "9999",
                            },
                            {
                              name: `10 ${language === "KOR" ? "초" : "sec"}`,
                              value: "10",
                            },
                            {
                              name: `20 ${language === "KOR" ? "초" : "sec"}`,
                              value: "20",
                            },
                            {
                              name: `30 ${language === "KOR" ? "초" : "sec"}`,
                              value: "30",
                            },
                            {
                              name: `1 ${language === "KOR" ? "분" : "min"}`,
                              value: "60",
                            },
                            {
                              name: `2 ${language === "KOR" ? "분" : "min"}`,
                              value: "120",
                            },
                            {
                              name: `3 ${language === "KOR" ? "분" : "min"}`,
                              value: "180",
                            },
                          ]}
                          value={rawData.refresh_time}
                          changeFun={inputHandler}
                          def={rawData.refresh_time}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {language === "KOR"
                          ? "리스트 로딩개수"
                          : "List loading count"}
                      </th>
                      <td colSpan={1}>
                        <Select
                          id="render_cnt"
                          short={"N"}
                          name="render_cnt"
                          options={[
                            {
                              name: `20 ${"개"}`,
                              value: "20",
                            },
                            {
                              name: `30 ${"개"}`,
                              value: "30",
                            },
                            {
                              name: `50 ${"개"}`,
                              value: "50",
                            },
                            {
                              name: `100 ${"개"}`,
                              value: "100",
                            },
                          ]}
                          value={rawData.render_cnt}
                          changeFun={inputHandler}
                          def={rawData.render_cnt}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
