import { useState, useEffect } from "react";

export default function Email({ id, name, value, setRawData }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]/.test(email);
  }

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
      setRawData((prev) => ({ ...prev, [name]: event.target.value }));
    }

    setEmail(event.target.value);
  };
  const style = {
    color: error ? "red" : "",
  };
  useEffect(() => {
    setEmail(value);
  }, [value]);

  return (
    <div className="custom-input">
      <input
        style={style}
        id={id}
        name={name}
        value={email}
        onChange={handleChange}
      />
    </div>
  );
}
