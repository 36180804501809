import React, { useState } from "react";
import { ISODateToDateTime, add_hyphen, checkValidObj } from "../../../lib/common_func";
import Swal from "sweetalert2";
import axios from "axios";
import { useLang } from "../../../context/LanguageContext";

export default function ManualFixModal({
  data,
  searchOption,
  unit_nm,
  unitid,
  closeModal,
  search,
}) {
  const { language } = useLang();
  const [sendData, setSendData] = useState(data);

  const AddDataSave = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: '수정하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then(async (res) => {
        if(res.isConfirmed) {
            const check = ['dist','d_commute','d_general','gas_costs','etc_costs','tolls','parking_costs'];
            const keys = Object.keys(sendData);
            let isValid = false;
            // number value check
            keys.map((key) => {
                if(check.includes(key) && Number.isNaN(parseFloat(sendData[key]))) {
                    Swal.fire({
                        title:'유효하지 않은 숫자값이 있습니다.'
                    })
                    isValid = true;
                    return ;
                }
        
            })

            if(isValid) return ;
            // dist cehck
            if(parseFloat(sendData.dist) < parseFloat(sendData.d_commute) + parseFloat(sendData.d_general)) {
                Swal.fire({
                    title:'주행거리는 출퇴근 거리와 업무용 거리의 합과 같거나 더 커야 합니다.'
                })
                return ;
            }
                
            const test = {...sendData,
                unitid: unitid,
                ed_time: ISODateToDateTime(sendData.ed_time),
                change_dist: parseFloat(sendData.dist) - parseFloat(data.dist)
            }

            if(!checkValidObj(test)) {
                Swal.fire({
                  title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
                })
                return;
            }
    
            await axios.post("/web/driving/detail/edit/man", test).then((res) => {
                Swal.fire({
                title : '수정되었습니다',
                confirmButtonText : '확인',
                }).then((res) => {
                closeModal();
                search(prev => !prev);
                })        
            }).catch((err) => {
            Swal.fire({
                title : '수정에 실패하였습니다',
                confirmButtonText : '확인',
            })
            console.log('err : ', err);
            })
        }
    })};
  const isNumberDecimalChk = (e) => {
    const valid = ".0123456789"; // 유효한 값
    let ok = "yes";
    let temp;
    let dotCount = 0;
    const value = e.target.value;
    const length = value.length;

    for (let i = 0; i < length; i++) {
      temp = "" + value.substring(i, i + 1);
      if (e.target.value.substring(i, i + 1) === ".") dotCount++;
      if (valid.indexOf(temp) == "-1") ok = "no";
    }

    if (ok == "no") {
        Swal.fire({
            title: "올바르지 않은 값입니다",
        });
    } else if (dotCount > 1) {
        setSendData((prev) => ({...prev, [e.target.name]: value.substring(0, length - 1)}));
        Swal.fire({
            title: "소수점은 하나만 입력 가능합니다",
        });
    } else {
        const dotIndex = `${value}`.indexOf('.');
        setSendData((prev) => ({...prev, [e.target.name]: dotIndex === -1 ? value : `${value}`.substring(0, dotIndex + 3)}));
    }
  };

  const handleChange = (e) => {
    const check = ['gas_costs','etc_costs','tolls','parking_costs'];
    if(check.includes(e.target.name) && e.target.value === '') {
        setSendData((prev) => ({...prev, [e.target.name]:0}));
    } else if (check.includes(e.target.name) && isNaN(e.target.value)) {
    } else {
        setSendData((prev) => ({...prev, [e.target.name]:e.target.value}));
    }
  };

  return (
    <>
      <div id="crew-search-panel" className="common-modal on">
                <div className="modal-inner manual">
                    <div className="modal-page">
                    <div className="page-header">
                        <div className="page-title">
                        <span className='blue'>{language === "KOR" ? "세부내역 수정" : "Detail Info"}</span>
                        </div>
                        <button onClick={closeModal} type="button" className="modal-close">
                            <i className="ri-close-fill icon"></i>
                        </button>
                    </div>
                    <div className="page-body">
                        <form onSubmit={AddDataSave}>
                            <table className='m_table' cellpadding="0" cellspacing="0" width="100%" id="modal_table">
                                <tbody>
                                    <tr>
                                        <td className='m_title'>단말정보</td>
                                        <td className='key'>단말기명</td>
                                        <td className='value blue'><div>{(unit_nm ?? '')}</div></td>
                                        <td className='key'>단말번호</td>
                                        <td className='value blue'><div>{(unitid && add_hyphen(unitid)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>주행정보</td>
                                        <td className='key'>주행 시작시간</td>
                                        <td className='value'><div>{(sendData && ISODateToDateTime(sendData.st_time)) ?? ''}</div></td>
                                        <td className='key'>주행 종료시간</td>
                                        <td className='value'><div>{(sendData && ISODateToDateTime(sendData.ed_time)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>주행 전 거리(km)</td>
                                        <td className='value'><div>{(sendData && parseFloat(sendData.st_mileage).toFixed(2)) ?? ''}</div></td>
                                        <td className='key'>주행 후 거리(km)</td>
                                        <td className='value'><div>{(sendData && parseFloat(sendData.ed_mileage).toFixed(2)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>운전자정보</td>
                                        <td className='key'>운전자명</td>
                                        <td className='value'><div>{(sendData?.u_nm)}</div></td>
                                        <td className='key'>부서</td>
                                        <td className='value'><div>{(sendData?.department)}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>거리정보</td>
                                        <td className='key'>주행거리(km)</td>
                                        <td className='value'>
                                            <input className='small' name="dist" value={isNaN(parseFloat(sendData?.dist)) ? '' : sendData?.dist} onChange={isNumberDecimalChk}/>
                                            <input name="o_dist" value={sendData?.dist ?? 0} type="hidden" readonly />
                                        </td>
                                        <td className='key'></td>
                                        <td className='value'></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>출퇴근용(km)</td>
                                        <td className='value'><input className='small' name="d_commute" value={isNaN(parseFloat(sendData?.d_commute)) ? '' : sendData?.d_commute} onChange={isNumberDecimalChk}/></td>
                                        <td className='key'>일반업무용(km)</td>
                                        <td className='value'><input name="d_general" value={isNaN(parseFloat(sendData?.d_general)) ? '' : sendData?.d_general} onChange={isNumberDecimalChk}/></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>비용정보</td>
                                        <td className='key'>주유비용(원)</td>
                                        <td className='value'>
                                            <input className='small' name="gas_costs" value={sendData?.gas_costs ?? 0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const length = value.length;
                                                    
                                                    handleChange({target : {
                                                      name : e.target.name,
                                                      value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                                    }});
                                                }}
                                            />
                                        </td>
                                        <td className='key'>통행비용(원)</td>
                                        <td className='value'>
                                            <input name='tolls' value={sendData?.tolls ?? 0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const length = value.length;
                                                    
                                                    handleChange({target : {
                                                      name : e.target.name,
                                                      value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                                    }});
                                                }}
                                            />
                                        </td>                                        
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>기타비용(원)</td>
                                        <td className='value'>
                                            <input className='small' name='etc_costs' value={sendData?.etc_costs ?? 0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const length = value.length;
                                                    
                                                    handleChange({target : {
                                                      name : e.target.name,
                                                      value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                                    }});
                                                }}
                                            />
                                        </td>
                                        <td className='key'>주차비용(원)</td>
                                        <td className='value'>
                                            <input name='parking_costs' value={sendData?.parking_costs ?? 0}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    const length = value.length;
                                                    
                                                    handleChange({target : {
                                                      name : e.target.name,
                                                      value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                                    }});
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>기타</td>
                                        <td className='key'>메모</td>
                                        <td colspan="3"><input id="m_memo" name='memo' value={sendData?.memo ?? ''} maxLength="15" onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>
                                            <button type='submit' className='modify'>수정</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
    </>
  );
}