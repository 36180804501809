import PropType from "prop-types";
import { useState, useEffect } from "react";
import React from "react";
import EventForm from "../organism/EventForm";
import { LanguageContext } from "../../../../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
// import LoadingIcon from "../../../assets/img/common/icon-loading.svg";
import Swal from "sweetalert2";
import axios from "../../../../../lib/axios";
import { useAuth } from "../../../../../context/LoginContext";
import { checkValidInput, checkValidObj } from "../../../../../lib/common_func";

export default function EditEvent({ idx, rawData, setIsOn }) {
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({});

  useEffect(() => {
    if (rawData && idx) {
      const event = rawData.find((event) => event.idx === idx);
      setState({...event, memo : event.memo ? event.memo : '', u_idx : user.idx});
    }
  }, [idx, rawData]);

  console.log(state.allday);
  useEffect(() => {
    if (state !== undefined) {
      const dateStart = new Date(state.fromDate);
      const dateEnd = new Date(state.toDate);
      const compareDate = dateStart.getTime() - dateEnd.getTime();
      if (compareDate > 0) {
        alert(
          language === "KOR"
            ? "시작일이 종료일보다 빠를 수 없습니다."
            : "Start date must be less than end date"
        );
        setState({ ...state, fromDate: "", toDate: "" });
      }
    }
  }, [state, language]);

  const onChange = useCallback(
    (key, value) => {
      setState({ ...state, [key]: value });
    },
    [state]
  );

  const handleDelete = () => {
    Swal.fire({
      title: language === "KOR" ? "삭제하시겠습니까?" : "Delete?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(!checkValidInput({idx : state.idx})) {
          Swal.fire({
            title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
          })
          return;
        }

        try {
          const res = await axios.post("/web/schedule/del", {
            idx: state.idx,
            type: "del",
          });
          if (res) {
            alert(language === "KOR" ? "삭제되었습니다." : "Deleted", language);
            window.location.reload();
          }
        } catch (err) {
          alert(
            language === "KOR" ? "삭제에 실패하였습니다." : "Failed delete",
            language
          );
        }
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.title === "") {
      Swal.fire({
        title:
          language === "KOR" ? "제목을 입력해주세요." : "Please enter a title.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }
    if (state.driver === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "운전자명을 입력해주세요."
            : "Please enter a driver.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.unitid === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "단말기명을 입력해주세요."
            : "Please enter a device.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.fromDate === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "시작일을 입력해주세요."
            : "Please enter a start date.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.toDate === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "종료일을 입력해주세요."
            : "Please enter a end date.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }
    if (state.fromTime === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "시작시간을 입력해주세요."
            : "Please enter a start time.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });
      return;
    }
    if (state.toTime === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "종료시간을 입력해주세요."
            : "Please enter a end time.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
      });

      return;
    }

    Swal.fire({
      title:
        language === "KOR" ? "수정하시겠습니까?" : "Would you like to save?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(!checkValidObj(state)) {
          Swal.fire({
            title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
          })
          return;
        }
        axios
          .post("/web/schedule/edit", state)
          .then((response) => {
            if (response.status === 200) {
              alert(language === "KOR" ? "수정되었습니다." : "Saved", language);
              window.location.reload();
            } else if (response.status === 500) {
              console.error("Server error: ", response.data);
              alert(
                language === "KOR"
                  ? "서버 오류가 발생했습니다."
                  : "Server error occurred",
                language
              );
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
            Swal.fire({
              title:
                language === "KOR"
                  ? "오류가 발생하였습니다."
                  : "An error occurred",
              confirmButtonText: language === "KOR" ? "확인" : "OK",
              showCancelButton: true,
            });
          });
      }
    });
  };

  return (
    <div className="common-modal on" style={{ zIndex: "1001" }}>
      <div className="modal-inner">
        <div className="modal-page">
          <div className="page-header">
            <div className="page-title">
              <span>{language === "KOR" ? "차량예약" : "Reservation"}</span>
            </div>
            <button
              type="button"
              className="modal-close"
              onClick={() => setIsOn(false)}
            >
              <i className="ri-close-fill icon"></i>
            </button>
          </div>
          <div className="page-body">
            {state && (
              <EventForm
                isOpen={isOpen}
                handlerClick={setIsOpen}
                state={state}
                updateState={onChange}
                cancelFun={handleDelete}
                submitFun={handleSubmit}
                deleltetitle={language === "KOR" ? "삭제" : "Delete"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

EditEvent.propTypes = {
  data: PropType.object,
  sumbit: PropType.func,
};
