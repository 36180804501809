import React, { useState, useEffect } from "react";
import { useRef } from "react";
import Right from "../modal/ModalRightEdit";
import Left from "../modal/ModalLeftEdit";
import axios from "../../../lib/axios";
import Swal from "sweetalert2";
import { checkValidObj } from "../../../lib/common_func";

export default function GeoFenceAdd({ openClose, language, selectedData }) {
  //===================================================================================================

  const [fenceData, setFenceData] = useState({});
  const [data, setData] = useState({});
  const addrRef = useRef("");

  function changeFun(e) {
    setFenceData({
      ...fenceData,
      [e.target.name]: e.target.value,
    });
  }
  async function sumbitFun() {
    if (fenceData.addr === "") {
      Swal.fire({
        title:
          language === "KOR" ? "주소를 입력해주세요" : "Please enter address",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
        width: "400px",
        padding: "20px",
        heightAuto: false,
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    if (fenceData.region_nm === "") {
      Swal.fire({
        title:
          language === "KOR"
            ? "지역명을 입력해주세요"
            : "Please enter geofence name",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
        width: "400px",
        padding: "20px",
        heightAuto: false,
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    if (fenceData.radius === "" && fenceData.radius < 500) {
      Swal.fire({
        title:
          language === "KOR" ? "반경을 입력해주세요" : "Please enter radius",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
        width: "400px",
        padding: "20px",
        heightAuto: false,
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }

    if(!checkValidObj(fenceData)) {
      Swal.fire({
        title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
      })
      return;
    }

    try {
      await axios.post(`/web/region/edit`, fenceData).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  function sumbitInit() {
    Swal.fire({
      title:
        language === "KOR" ? "저장하시겠습니까?" : "Would you like to save?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      heightAuto: false,
      customClass: {        
        cancelButton: "swal-cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        sumbitFun();
      }
    });
  }
  //===================================================================================================
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedData) {
          const response = await axios.get(
            `/web/region/detail?idx=${selectedData}`
          );
          setFenceData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedData]);

  const isDataLoaded = Object.keys(fenceData).length > 0;
  

  return (
    <>
      <div id="geo-panel" className={"common-modal on"}>
        <div className="modal-inner">
          <div className="geo-wrapper">
            {isDataLoaded && (
              <>
                <Right
                  language={language}
                  setData={setData}
                  data={data}
                  fenceData={fenceData}
                  changeFun={changeFun}
                  addrRef={addrRef}
                  setFenceData={setFenceData}
                />
                <Left
                  language={language}
                  sumbitFun={sumbitInit}
                  data={data}
                  openClose={openClose}
                  fenceData={fenceData}
                  changeFun={changeFun}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
