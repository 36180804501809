import React, { useEffect, useRef, useState } from "react";
import { ISODateToDateTime, add_hyphen, checkValidObj } from "../../../lib/common_func";
import Swal from "sweetalert2";
import InputFile from "../../Atomic/atoms/InputFile";
import axios from "axios";
import { useLang } from "../../../context/LanguageContext";

export default function FixModal({
  data,
  searchOption,
  unit_nm,
  unitid,
  closeModal,
  search,
}) {
  const { language } = useLang();
  const [sendData, setSendData] = useState(data);
  const stImgInput = useRef();
  const edImgInput = useRef();
  const stImgName = useRef();
  const edImgName = useRef();
  const stImg = useRef();
  const edImg = useRef();
  const addStImgBtn = useRef();
  const addEdImgBtn = useRef();

  const AddDataSave = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: '수정하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then(async (res) => {
      if(res.isConfirmed) {
        const check = ['dist','d_commute','d_general','gas_costs','etc_costs','tolls','parking_costs'];
        const keys = Object.keys(sendData);
        let isValid = false;
        // number value check
        keys.map((key) => {
            if(check.includes(key) && Number.isNaN(parseFloat(sendData[key]))) {
                Swal.fire({
                    title:'유효하지 않은 숫자값이 있습니다.'
                })
                isValid = true;
                return ;
            }
    
        })

        if(isValid) return ;
        // dist cehck
        if(parseFloat(sendData.dist) < parseFloat(sendData.d_commute) + parseFloat(sendData.d_general)) {
            Swal.fire({
                title:'주행거리는 출퇴근 거리와 업무용 거리의 합과 같거나 더 커야 합니다.'
            })
            return ;
        }

        let formatData = {...sendData};
        keys.map((key) => {
            if(sendData[key] == null) {
                formatData[key] = '';
            }
        })

        if(!checkValidObj({...formatData,
          unitid: unitid,
          st_img_ori: stImg.current.children[0] ? stImgInput.current.files[0]?.name ?? sendData?.st_img ?? '' : '',
          ed_img_ori: edImg.current.children[0] ? edImgInput.current.files[0]?.name ?? sendData?.ed_img ?? '' : '',
          change_dist: parseFloat(sendData.dist) - parseFloat(data.dist),
        })) {
          Swal.fire({
            title : (language === 'KOR' ? '포함될 수 없는 문자가 존재합니다.' : 'Invalid character exists')
          })
          return;
        }
    
        const config = {
          header: { "content-type": "multipart/form-data" },
        };
        const formData = new FormData();
    
        formData.append("img_s", stImgInput.current.files[0] ?? '');
        formData.append("img_e", edImgInput.current.files[0] ?? '');
        formData.append("idx", sendData.idx);
        if(stImg.current.children[0]) {
            formData.append("st_img", stImgInput.current.files[0]?.name ?? sendData?.st_img ?? '');
        } else {
            formData.append("st_img", '');
        }
        if(edImg.current.children[0]) {
            formData.append("ed_img", edImgInput.current.files[0]?.name ?? sendData?.ed_img ?? '');
        } else {
            formData.append("ed_img", '');
        }
        await axios
        .post("/web/upload/log/backup", formData, config)
        .then(async (res) => {
          if (res.status === 200) {
            //   console.log("build upload");
          }
        });
        await axios
          .post("/web/upload/log", formData, config)
          .then(async (res) => {
            
            const test = {...formatData,
                unitid: unitid,
                ed_time: ISODateToDateTime(sendData.ed_time),
                st_img: stImg.current.children[0] ? (res.data.startImg || sendData?.st_img || '') : '', 
                st_img_ori: stImg.current.children[0] ?  (res.data.startImg_ori || stImgInput.current?.files[0]?.name || sendData?.st_img_ori || '') : '', 
                ed_img: edImg.current.children[0] ? (res.data.endImg || sendData?.ed_img || '') : '', 
                ed_img_ori: edImg.current.children[0] ? (res.data.endImg_ori || edImgInput.current?.files[0]?.name || sendData?.ed_img_ori || '') : '',
                change_dist: parseFloat(sendData.dist) - parseFloat(data.dist),
            }
    
            await axios.post("/web/driving/detail/item/edit", test).then((res) => {
                console.log('edit res : ', res);
                Swal.fire({
                  title : '수정되었습니다',
                  confirmButtonText : '확인',
                }).then((res) => {
                  closeModal();
                  search(prev => !prev);
                })        
            }).catch((err) => {
              Swal.fire({
                title : '수정에 실패하였습니다',
                confirmButtonText : '확인',
              })
              console.log('err : ', err);
            })
          })
          .catch((err) => {
            Swal.fire({
              title : '수정에 실패하였습니다',
              confirmButtonText : '확인',
            })
            console.log("err : ", err);
          });
      }
    })
  };
  const isNumberDecimalChk = (e) => {
    const valid = ".0123456789"; // 유효한 값
    let ok = "yes";
    let temp;
    let dotCount = 0;
    const value = e.target.value;
    const length = value.length;

    for (let i = 0; i < length; i++) {
      temp = "" + value.substring(i, i + 1);
      if (value.substring(i, i + 1) === ".") dotCount++;
      if (valid.indexOf(temp) == "-1") ok = "no";
    }

    if (ok == "no") {
      Swal.fire({
        title: "올바르지 않은 값입니다",
      });
    } else if (dotCount > 1) {
      setSendData((prev) => ({...prev, [e.target.name]: value.substring(0, length - 1)}));
      Swal.fire({
        title: "소수점은 하나만 입력 가능합니다",
      });
    } else {
      const dotIndex = `${value}`.indexOf('.');
      setSendData((prev) => ({...prev, [e.target.name]: dotIndex === -1 ? value : `${value}`.substring(0, dotIndex + 3)}));
    }
  };
  const ImgSelect = (e) => {
    if (e.target.name === "stImgBtn") {
      stImgInput?.current && stImgInput.current.click();
    } else if (e.target.name === "edImgBtn") {
      edImgInput?.current && edImgInput.current.click();
    }
  };
  const showImg = (e) => {
    if (e.target.name === "st_img") {
        if (stImg && stImg?.current) {
            hideImg("st");
        }
        if(e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                stImgName.current.innerHTML = stImgInput.current.files[0].name;
                let img = document.createElement("img");
                img.setAttribute("src", e.target.result);
                img.className = "m_st_img";
                img.alt = "your st image";
                //   img.onclick = function () {
                //     window.open(e.target.result);
                //   };
                stImg.current.append(img);
                let btn = document.createElement("button");
                btn.className = "delete";
                btn.innerHTML = "파일삭제";
                btn.onclick = function () {
                hideImg("st");
                };
                addStImgBtn.current.append(btn);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
      
    } else if (e.target.name === "ed_img") {
        if (edImg && edImg?.current) {
            hideImg("ed");
        }
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                edImgName.current.innerHTML = edImgInput.current.files[0].name;
                let img = document.createElement("img");
                img.setAttribute("src", e.target.result);
                img.className = "m_ed_img";
                img.alt = "your ed image";
                //   img.onclick = function () {
                //     window.open(e.target.result);
                //   };
                edImg.current.append(img);
                let btn = document.createElement("button");
                btn.className = "delete";
                btn.innerHTML = "파일삭제";
                btn.onclick = function () {
                    hideImg("ed");
                };
                addEdImgBtn.current.append(btn);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    }
  };
  const hideImg = (e) => {
    if (e === "st") {
      if (stImg.current.children[0]) stImg.current.children[0].remove();
      if (addStImgBtn.current.children[1])
        addStImgBtn.current.children[1].remove();
      if (stImgName.current) stImgName.current.innerHTML = "";
    } else if (e === "ed") {
      if (edImg.current.children[0]) edImg.current.children[0].remove();
      if (addEdImgBtn.current.children[1])
        addEdImgBtn.current.children[1].remove();
      if (edImgName.current) edImgName.current.innerHTML = "";
    }
  };
  const handleChange = (e) => {
    const check = ['gas_costs','etc_costs','tolls','parking_costs'];
    if(check.includes(e.target.name) && e.target.value === '') {
        setSendData((prev) => ({...prev, [e.target.name]:0}));
    } else if (check.includes(e.target.name) && isNaN(e.target.value)) {
    } else {
        setSendData((prev) => ({...prev, [e.target.name]:e.target.value}));
    }
  };

  useEffect(() => {
    if (data?.st_img) {
        let img = document.createElement("img");
        img.src = `../../../uploads/logs/${data.st_img}`;
        img.alt = "your st image";
        img.className = "m_st_img";
        img.onclick = function () {
          window.open(`../../../uploads/logs/${data.st_img}`);
        };
        stImg.current.append(img);
        let btn = document.createElement("button");
        btn.className = "delete";
        btn.innerHTML = "파일삭제";
        btn.onclick = function () {
          hideImg("st");
        };
        addStImgBtn.current.append(btn);
        stImgName.current.innerHTML = sendData.st_img_ori;
    }
    if (data?.ed_img) {
        let img = document.createElement("img");
        img.src = `../../../uploads/logs/${data.ed_img}`;
        img.alt = "your ed image";
        img.className = "m_ed_img";
        img.onclick = function () {
          window.open(`../../../uploads/logs/${data.ed_img}`);
        };
        edImg.current.append(img);
        let btn = document.createElement("button");
        btn.className = "delete";
        btn.innerHTML = "파일삭제";
        btn.onclick = function () {
          hideImg("ed");
        };
        addEdImgBtn.current.append(btn);
        edImgName.current.innerHTML = sendData.ed_img_ori;
    }
  }, [data]);

  return (
    <>
      <div id="crew-search-panel" className="common-modal on">
                <div className="modal-inner">
                    <div className="modal-page">
                    <div className="page-header">
                        <div className="page-title">
                        <span className='blue'>{language === "KOR" ? "세부내역 수정" : "Detail Info"}</span>
                        </div>
                        <button onClick={closeModal} type="button" className="modal-close">
                            <i className="ri-close-fill icon"></i>
                        </button>
                    </div>
                    <div className="page-body">
                        <form onSubmit={AddDataSave}>
                            <table className='m_table' cellpadding="0" cellspacing="0" width="100%" id="modal_table">
                                <tbody>
                                    <tr>
                                        <td className='m_title'>단말정보</td>
                                        <td className='key'>단말기명</td>
                                        <td className='value blue'><div>{(unit_nm ?? '')}</div></td>
                                        <td className='key'>단말번호</td>
                                        <td className='value blue'><div>{(unitid && add_hyphen(unitid)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>주행정보</td>
                                        <td className='key'>주행 시작시간</td>
                                        <td className='value'><div>{(sendData && ISODateToDateTime(sendData.st_time)) ?? ''}</div></td>
                                        <td className='key'>주행 종료시간</td>
                                        <td className='value'><div>{(sendData && ISODateToDateTime(sendData.ed_time)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>주행 전 거리(km)</td>
                                        <td className='value'><div>{(sendData && parseFloat(sendData.st_mileage).toFixed(2)) ?? ''}</div></td>
                                        <td className='key'>주행 후 거리(km)</td>
                                        <td className='value'><div>{(sendData && parseFloat(sendData.ed_mileage).toFixed(2)) ?? ''}</div></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>주행 전 계기판</td>
                                        <td className="m_st_img" ref={stImg}></td>
                                        <td className='key'>주행 후 계기판</td>
                                        <td className="m_ed_img" ref={edImg}></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td ref={addStImgBtn}>
                                            <button type='button' name="stImgBtn" style={{backgroudColor:'#8DAEE0'}} onClick={ImgSelect}>파일선택</button>
                                        </td>
                                        <td className='value'>
                                            <div ref={stImgName} class="name_img"></div>
                                        </td>
                                        <td ref={addEdImgBtn}>
                                            <button type='button' name='edImgBtn' style={{backgroudColor:'#8DAEE0'}} onClick={ImgSelect}>파일선택</button>
                                        </td>
                                        <td className='value'>			
                                            <div ref={edImgName} class="name_img"></div>						
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>운전자정보</td>
                                        <td className='key'>운전자명</td>
                                        <td className='value'><input className='small' name="u_nm" maxLength="12" value={sendData?.u_nm ?? ''} onChange={handleChange}/></td>
                                        <td className='key'>부서</td>
                                        <td className='value'><input name="department" maxLength="12" value={sendData?.department ?? ''} onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>거리정보</td>
                                        <td className='key'>주행거리(km)</td>
                                        <td className='value'>
                                            <input className='small' name="dist" value={isNaN(parseFloat(sendData?.dist)) ? '' : sendData?.dist} onChange={isNumberDecimalChk}/>
                                            <input name="o_dist" value={sendData?.dist ?? 0} type="hidden" readonly />
                                        </td>
                                        <td className='key'></td>
                                        <td className='value'></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>출퇴근용(km)</td>
                                        <td className='value'><input className='small' name="d_commute" value={isNaN(parseFloat(sendData?.d_commute)) ? '' : sendData?.d_commute} onChange={isNumberDecimalChk}/></td>
                                        <td className='key'>일반업무용(km)</td>
                                        <td className='value'><input name="d_general" value={isNaN(parseFloat(sendData?.d_general)) ? '' : sendData?.d_general} onChange={isNumberDecimalChk}/></td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>비용정보</td>
                                        <td className='key'>주유비용(원)</td>
                                        <td className='value'>
                                          <input className='small' name="gas_costs" value={sendData?.gas_costs ?? 0}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              const length = value.length;
                                              
                                              handleChange({target : {
                                                name : e.target.name,
                                                value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                              }});
                                            }}
                                          />
                                        </td>
                                        <td className='key'>통행비용(원)</td>
                                        <td className='value'>
                                          <input name='tolls' value={sendData?.tolls ?? 0}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              const length = value.length;
                                              
                                              handleChange({target : {
                                                name : e.target.name,
                                                value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                              }});
                                            }}
                                          />
                                        </td>                                        
                                    </tr>
                                    <tr>
                                        <td className='m_title'></td>
                                        <td className='key'>기타비용(원)</td>
                                        <td className='value'>
                                          <input className='small' name='etc_costs' value={sendData?.etc_costs ?? 0}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              const length = value.length;
                                              
                                              handleChange({target : {
                                                name : e.target.name,
                                                value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                              }});
                                            }}
                                          />
                                        </td>
                                        <td className='key'>주차비용(원)</td>
                                        <td className='value'>
                                          <input name='parking_costs' value={sendData?.parking_costs ?? 0}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              const length = value.length;
                                              
                                              handleChange({target : {
                                                name : e.target.name,
                                                value : !/[0-9]/.test(value.substring(length - 1 , length)) ? value.substring(0, length -1) : value
                                              }});
                                            }}
                                          />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='m_title'>기타</td>
                                        <td className='key'>메모</td>
                                        <td colspan="3"><input id="m_memo" name='memo' value={sendData?.memo ?? ''} maxLength="15" onChange={handleChange}/></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={5}>
                                            <button type='submit' className='modify'>수정</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <InputFile imgRef={stImgInput} name={'st_img'} fileSize={2} changeFun={showImg} style={{display:'none'}}/>
                                <InputFile imgRef={edImgInput} name={'ed_img'} fileSize={2} changeFun={showImg} style={{display:'none'}}/>
                            </table>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
    </>
  );
}